import { gql } from "@apollo/client";

export const GETSUBUSERS = gql`
  query GETSUBUSERS {
    news_letter_subscriber {
      id
      email
      aggregate {
        count
      }
    }
    news_letter_subscriber_aggregate {
      count
    }
  }
`;
export const GETSTUDENTUSERS = gql`
  query GETSTUDENTUSERS($limit: Int!, $offset: Int!) {
    users(
      where: { is_instructor: { exact: "False" }, is_superuser: { exact: "False" } }
      limit: $limit
      offset: $offset
    ) {
      id
      email
      is_active
      is_instructor
      is_superuser
      studentprofile {
        first_name
        last_name
        bio
        profile_picture
      }
    }
  }
`;
export const SEARCHSTUDENTUSERS = gql`
  query GETSTUDENTUSERS($search: String) {
    student_profile(where: { first_name: { contains: $search } }) {
      first_name
      last_name
      bio
      profile_picture
      user {
        id
        email
        is_active
        is_instructor
        is_superuser
      }
    }
  }
`;

export const GETSTUDENTUSERSCOUNT = gql`
  query GETSTUDENTUSERSCOUNT {
    users_aggregate(
      where: { is_instructor: { exact: "False" }, is_superuser: { exact: "False" } }
    ) {
      count
    }
  }
`;

export const GETINSTRUCTORUSERS = gql`
  query GETINSTRUCTORUSERS($limit: Int!, $offset: Int!) {
    users(where: { is_instructor: { exact: "True" } }, limit: $limit, offset: $offset) {
      id
      email
      is_active
      is_instructor
      is_superuser
      studentprofile {
        first_name
        last_name
        bio
        profile_picture
      }
    }
  }
`;

export const GETINSTRUCTORUSERSCOUNT = gql`
  query GETINSTRUCTORUSERSCOUNT {
    users_aggregate(where: { is_instructor: { exact: "True" } }) {
      count
    }
  }
`;

export const GETADMINUSERS = gql`
  query GETADMINUSERS {
    users(where: { is_superuser: { exact: "True" } }) {
      id
      email
      is_active
      is_instructor
      is_superuser
      studentprofile {
        first_name
        last_name
        bio
        profile_picture
      }
    }
  }
`;

export const GETADMINUSERSCOUNT = gql`
  query GETADMINUSERSCOUNT {
    users_aggregate(where: { is_superuser: { exact: "True" } }) {
      count
    }
  }
`;

export const GETCOURSECOUNT = gql`
  query GETCOURSECOUNT($status: String) {
    course_aggregate(where: { status: { exact: $status } }) {
      count
    }
  }
`;

export const GETCOURSE = gql`
  query GETCOURSE($offset: Int, $limit: Int, $status: String) {
    course(offset: $offset, limit: $limit, where: { status: { exact: $status } }) {
      id
      name
      status
      is_deleted
      is_approved
      instructor {
        email
        studentprofile {
          first_name
          last_name
        }
      }
      enrollments {
        aggregate {
          count
        }
      }
      category {
        name
      }
      publish_date
    }
  }
`;

export const GETFEEDBACK = gql`
  query GETFEEDBACK($courseId: String, $offset: Int, $limit: Int) {
    course_submission_feedback(
      where: { course_id: { exact: $courseId } }
      offset: $offset
      limit: $limit
    ) {
      id
      feedback
      feedback_title
      course {
        id
        name
        instructor {
          id
          email
        }
      }
    }
  }
`;
export const GETFEEDBACKBYINSTRUCTORID = gql`
  query GETFEEDBACK($course_id: String, $offset: Int, $limit: Int) {
    course_submission_feedback(
      where: { course_id: { exact: $course_id } }
      offset: $offset
      limit: $limit
    ) {
      id
      feedback
      feedback_title
      course {
        id
        name
        instructor {
          id
          email
        }
      }
      aggregate {
        count
      }
    }
  }
`;
export const GETFEEDBACKBYADMIN = gql`
  query GETFEEDBACK($offset: Int, $limit: Int) {
    course_submission_feedback(offset: $offset, limit: $limit) {
      id
      feedback
      feedback_title
      course {
        id
        name
        instructor {
          id
          email
        }
      }
      aggregate {
        count
      }
    }
  }
`;
export const GETFEEDBACKDETAIL = gql`
  query GetFeedbackDetail($id: String!) {
    course_submission_feedback(where: { id: { exact: $id } }) {
      id
      feedback_title
      feedback
      course {
        name
      }
    }
  }
`;

export const GETCONTACTSBYADMIN = gql`
  query GETCONTACTSBYADMIN($offset: Int, $limit: Int) {
    contact_us(offset: $offset, limit: $limit) {
      body
      email
      id
      subject
      aggregate {
        count
      }
    }
  }
`;
