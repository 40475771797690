import { useNavigate } from "react-router-dom";
import { REQUESTOTP, VERIFYOTP } from "./data/mutation";
import { useState } from "react";
import { TopToast } from "../../../components/Toast";
import { H3 } from "../../../components/Heading";
import OTPInput from "react-otp-input";
import { LandPrimaryButton } from "../../../components/Button";
import { useMutation } from "@apollo/client";
import { SetUserSession } from "../../../utils/setUserSession";

const Verify = () => {
  const navigate = useNavigate();
  const [close, setClose] = useState(false);
  const [status, setStatus] = useState({
    success: false,
    error: false,
    message: "",
  });

  const email = sessionStorage.getItem("signup_email");
  const OTP_REGEX = /^\d{6}$/;
  const [otp, setOtp] = useState("");
  const [validOtp, setValidOtp] = useState(true);

  const [verifyUser, { loading: verifyLoader }] = useMutation(VERIFYOTP);
  const [requestOtp, { loading: resendLoader }] = useMutation(REQUESTOTP);

  const handleVerification = async (e) => {
    e.preventDefault();

    if (!OTP_REGEX.test(otp)) {
      setValidOtp(false);
      setStatus({ success: false, error: true, message: "Invalid OTP" });
      setTimeout(() => setValidOtp(true), 2000);
      return;
    }

    try {
      var { data } = await verifyUser({ variables: { email, otp } });
      setOtp("");
      if (data?.verify_otp?.success) {
        setStatus({
          success: true,
          error: false,
          message: "Account Verified!",
        });
        SetUserSession();
        setTimeout(() => navigate("/login"), 2000);
      } else {
        setStatus({ success: false, error: true, message: "Invalid OTP" });
      }
    } catch (error) {
      const errorMessage =
        error?.graphQLErrors?.[0]?.message ||
        "Account Verification success. Login now";
      setStatus({
        success: data?.verify_otp?.success,
        error: false,
        message: errorMessage,
      });
      if (data?.verify_otp?.success) {
        setTimeout(() => navigate("/login"), 2000);
      }
    }
  };

  const handleResendOtp = async () => {
    try {
      await requestOtp({ variables: { email } });
      setStatus({
        success: true,
        error: false,
        message: "Verification code sent to your email.",
      });
    } catch (error) {
      const errorMessage =
        error?.graphQLErrors?.[0]?.message || "Something went wrong.";
      setStatus({ success: false, error: true, message: errorMessage });
    }
  };
  return (
    <>
      {status.error && (
        <TopToast
          text={"Invalid OTP!"}
          isSuccess={false}
          close={close}
          setClose={setClose}
        />
      )}
      {status.success && (
        <TopToast
          text={"Account Verification success. Login now"}
          isSuccess={true}
          close={close}
          setClose={setClose}
        />
      )}
      <form
        className="max-w-lg mx-auto px-4 rounded-xl lg:px-12 text-center"
        onSubmit={handleVerification}
      >
        <H3 text="Verify Account" />
        <p className="text-gray-500">
          Please insert the code and verify account.
        </p>
        {status.success ? (
          <p className="text-green-400 ">
            Verification successfull. Login now!
          </p>
        ) : null}
        <div className="text-gray-200 my-10 relative">
          <OTPInput
            value={otp}
            onChange={setOtp}
            numInputs={6}
            renderSeparator={<span className="text-purple-500 px-1"></span>}
            renderInput={(props) => <input {...props} />}
            inputStyle="rounded-lg w-9 h-10 bg-custom-gray-600 px-3 border border-gray-700 focus:ring-2 ring-purple-900 text-white text-lg"
            containerStyle="justify-center"
            skipDefaultStyles={true}
            required
          />
          {!validOtp && (
            <div className="bg-slate-800 rounded p-2 text-xs text-amber-700 absolute top-11 w-full">
              A 6 digit Code is required.
            </div>
          )}
        </div>

        <LandPrimaryButton
          type="submit"
          customStyle="w-full"
          text={verifyLoader ? "•••" : "Verify"}
        />
      </form>
      <p className="text-gray-500 my-4 text-center">
        Did not get OTP.{" "}
        <button
          type="button"
          onClick={handleResendOtp}
          className="text-gray-300 hover:text-custom-purple-700"
        >
          {resendLoader ? "Sending •••" : "Resend"}
        </button>
      </p>
    </>
  );
};

export default Verify;
