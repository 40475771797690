import React from "react";

const ErrorBanner = ({ errorText }) => {
  return (
    <div className="mt-4 flex justify-center items-center space-x-4 text-sm bg-red-300 text-black font-bold">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="size-6"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z"
        />
      </svg>
      {errorText.length < 35 ? (
        <div>
          <p>There was a problem logging in.</p>
          <p>Check your mail or password or</p>
          <p>Create an account</p>
        </div>
      ) : (
        errorText
      )}
    </div>
  );
};

export default ErrorBanner;
