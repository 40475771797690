// src/pages/FeedbackDetail.jsx

import React, { useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { GETFEEDBACKDETAIL } from './data/query';
import { AuthContext } from '../../../context/AuthContext';
import { Loader } from '../comment/component/Loader';

const FeedbackDetail = () => {
  const { feedbackId } = useParams();
  const { userId } = useContext(AuthContext);
  const navigate = useNavigate();

  const { data, loading, error } = useQuery(GETFEEDBACKDETAIL, {
    variables: { id: feedbackId },
  });

  if (loading) return <Loader />;
  if (error) return <p>Error: {error.message}</p>;

  const { feedback_title, feedback, course } =
    data?.course_submission_feedback?.[0];

  return (
    <div className="min-h-screen bg-gray-100 py-6 flex flex-col justify-center sm:py-12">
      <div className="relative py-3 sm:max-w-2xl sm:mx-auto">
        <div className="absolute inset-0 bg-gradient-to-r from-teal-400 to-blue-500 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl"></div>
        <div className="relative px-4 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-20">
          <div className="max-w-full">
            <h1 className="text-3xl font-bold text-center mb-6 text-gray-800">
              {feedback_title}
            </h1>
            <div className="divide-y divide-gray-200">
              <div className="py-8 text-base leading-6 text-gray-700 sm:text-lg sm:leading-7">
                <p className="whitespace-pre-wrap">{feedback}</p>
                <h2 className="text-2xl font-semibold mt-4">Course Details</h2>
                <p className="mt-2">
                  Course Name:{' '}
                  <span className="font-medium">{course.name}</span>
                </p>
                {/* Add more course details as needed */}
              </div>
              <div className="pt-6 text-base leading-6 font-bold sm:text-lg sm:leading-7">
                <button
                  onClick={() => navigate('/dashboard/feedbacks')}
                  className="text-teal-500 hover:text-teal-700"
                >
                  Back to Feedbacks
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeedbackDetail;
