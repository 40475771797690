import React, { useEffect, useState } from "react";
import { useLazyQuery, useQuery } from "@apollo/client";
import { TableLoader } from "../../../components/Loader";
import { TD, Table } from "../../../components/table/Table";
import { GETSTUDENTASSESMENT, GETSTUDENTSCORE } from "../assessment/data/query";

const AssesmentList = ({ courseId }) => {
  const [page, setPage] = useState(0);
  const [itemPerPage, setItemPerPage] = useState(5);
  // const [expandedFeedback, setExpandedFeedback] = useState({});
  const [filteredData, setFilteredData] = useState([]);
  const [studentsScore, setStudentsScore] = useState([]);

  // const { userId } = useContext(AuthContext);

  const { data, loading } = useQuery(GETSTUDENTASSESMENT);
  const [getScore] = useLazyQuery(GETSTUDENTSCORE);

  const thead = [{ head: "Student Name" }, { head: "Course Name" }, { head: "Score" }];

  const getStudentsScore = async (data) => {
    const promises = data.map(async (item) => {
      const { data } = await getScore({
        variables: {
          courseId: item.question.course.id,
          userId: item.user.id
        }
      });
      return { ...data, ...item.user };
    });
    const result = await Promise.all(promises);
    setStudentsScore(result);
  };

  useEffect(() => {
    if (data) {
      const filtered = data?.assessment?.filter(
        (items) => items.question?.course?.id === courseId
      );
      // merge the data with the same user id and get the highest score
      const merged = filtered.reduce((acc, item) => {
        const found = acc.find((el) => el.user.id === item.user.id);
        if (found) {
          if (found.score < item.score) {
            found.score = item.score;
          }
        } else {
          acc.push(item);
        }
        return acc;
      }, []);
      setFilteredData(merged);
      getStudentsScore(merged);
    }
    return () => {};
  }, [data]);

  return (
    <>
      {loading ? (
        <TableLoader />
      ) : (
        <>
          <Table
            title={"Assessments"}
            data={thead}
            noCrud={true}
            setPage={setPage}
            page={page}
            itemPerPage={itemPerPage}
            totalCount={data?.aggregate?.count}
          >
            {filteredData?.map(({ id, answer, question, user }, index) => {
              return (
                <tr className="border p-1 hover:bg-gray-50/90" key={id}>
                  <TD>{index + 1}</TD>
                  <TD>
                    {user?.studentprofile?.first_name
                      ? user?.studentprofile?.first_name +
                        " " +
                        user?.studentprofile?.last_name
                      : user.email}
                  </TD>
                  <TD>{question?.course?.name}</TD>
                  <TD>
                    {
                      studentsScore.find((item) => item.id === user.id)?.assessment_score
                        ?.score
                    }
                  </TD>
                </tr>
              );
            })}
          </Table>
        </>
      )}
    </>
  );
};

export default AssesmentList;
