import { useQuery } from "@apollo/client";
import { GETASSESSMENT, GETSTUDENTSCORE } from "../pages/dashboard/assessment/data/query";
import { useEffect, useState } from "react";

const useFetchScore = (course_id, user_id) => {
  const [loading, setLoading] = useState(true);
  const [assessment, setAssessment] = useState();
  const [tookAssessment, setTookAssessment] = useState();
  const [assessmentCount, setAssessmentCount] = useState();
  const [score, setScore] = useState();

  const { data: assessmentData, loading: assLoading } = useQuery(GETASSESSMENT, {
    variables: {
      courseId: course_id,
      limit: 5,
      offset: 0
    },
    fetchPolicy: "network-only"
  });

  const { data } = useQuery(GETSTUDENTSCORE, {
    variables: {
      courseId: course_id,
      userId: user_id
    },
    fetchPolicy: "network-only"
  });

  useEffect(() => {
    setAssessment(assessmentData?.question);
    setTookAssessment(data?.assessment_score?.took_assessment);
    setAssessmentCount(data?.assessment_score?.assessment_count);
    setScore(data?.assessment_score?.score);
    setLoading(false);
  }, [assessmentData, data, assLoading]);
  return { score, assessment, tookAssessment, assessmentCount, loading };
};

export default useFetchScore;
