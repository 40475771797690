import React, { useContext } from "react";
import { AuthContext } from "../../../../context/AuthContext";
import { useQuery } from "@apollo/client";
import { GETENROLLED } from "../../enroll/data/query";
import { DashH4, DashH5 } from "../../../../components/Heading";
import DataNotFound from "../../../../components/DataNotFound";
import { DashboardCourseProgressCard } from "../component/Card";
import { DashCardLoader } from "../component/Loader";
import { Common } from "../component/Common";

const Student = () => {
  const { userId } = useContext(AuthContext);

  const { data: enrolledCourse, loading } = useQuery(GETENROLLED, {
    variables: {
      studentId: userId
    }
  });

  return (
    <div>
      <DashH4 text="Welcome Back ☺" />
      <hr />
      <div className="pt-4">
        <DashH5 text="My Courses" />
        <div className="flex space-x-4 justify-start h-auto overflow-x-auto scrollbar-hide pt-4">
          {loading && (
            <div className="flex space-x-4">
              <DashCardLoader />
              <DashCardLoader />
            </div>
          )}
          {enrolledCourse?.course_enrollment?.length === 0 && (
            <DataNotFound text="You're not enrolled yet." />
          )}
          {enrolledCourse?.course_enrollment?.map(({ id, course }) => {
            const totals = course?.sections?.reduce((acc, { episodes }) => {
              return acc + episodes?.length;
            }, 0);

            const episodes = course?.sections?.map(({ episodes }) => episodes).flat();

            return (
              <DashboardCourseProgressCard
                key={id}
                totalVideos={totals}
                episodes={episodes}
                id={course?.id}
                userId={userId}
                bgColor="blue"
                course={course?.name}
                description={course?.description}
              />
            );
          })}
        </div>
        <hr className="py-2" />
        <DashH5 text="Course Analysis" />

        <div className="h-auto flex flex-wrap gap-4 mt-4">
          <div className="bg-gray-50 rounded-xl p-4 min-w-72 w-full md:w-fit min-h-72">
            {/* show Total price spent on this platform */}
            <p className="text-2xl mb-5 font-bold text-center">Total Spent</p>
            <p className=" font-bold text-start mb-3">Courses</p>
            {enrolledCourse?.course_enrollment?.map(({ course }) => (
              <div
                key={course.id}
                className="flex w-full my-1 justify-between text-[#66b2b2]"
              >
                <p className="font-bold text-center max-w-40 truncate">{course.name}</p>
                <p className="font-bold text-center">${course.price}</p>
              </div>
            ))}

            <p className="text-start text-[#66b2b2] mt-3 font-bold text-lg">
              Total: $
              {enrolledCourse?.course_enrollment?.reduce((acc, { course }) => {
                return Number(acc) + Number(course.price);
              }, 0)}
            </p>
          </div>

          <Common />
        </div>
      </div>
    </div>
  );
};

export default Student;
