import React, { useState } from "react";
import { GETSUBUSERS } from "./data/query";
import UserTable from "./component/UserTable";
import { useQuery } from "@apollo/client";
import { Table, TD } from "../../../components/table/Table";
import { TableLoader } from "../../../components/Loader";

const SubcribersList = () => {
  const [page, setPage] = useState(0);
  const [itemPerPage, setItemPerPage] = useState(5);

  const { data, loading } = useQuery(GETSUBUSERS, {
    fetchPolicy: "network-only"
  });

  const thead = [{ head: "Email" }];

  return (
    <>
      {loading ? (
        <TableLoader />
      ) : (
        <>
          <Table
            title="Users"
            data={thead}
            noCrud={true}
            setPage={setPage}
            page={page}
            itemPerPage={itemPerPage}
            totalCount={data?.news_letter_subscriber_aggregate?.count}
          >
            {data?.news_letter_subscriber?.map(({ id, email }, index) => {
              return (
                <>
                  <tr className="border p-1" key={id}>
                    <TD>{index + 1}</TD>

                    <TD colSpan={2} text={email} />
                  </tr>
                </>
              );
            })}
          </Table>
        </>
      )}
    </>
  );
};

export default SubcribersList;
