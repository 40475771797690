import React, { useEffect } from "react";
import swal from "sweetalert";

const checkInactivityTime = () => {
  const THREE_HOURS = 3 * 60 * 60 * 1000; // 3 hours in milliseconds

  const lastVisitTime = localStorage.getItem("lastVisitDateTime");
  const currentTime = new Date().getTime();

  if (lastVisitTime) {
    const timeDifference = currentTime - new Date(lastVisitTime).getTime();

    if (timeDifference > THREE_HOURS) {
      // Log the user out if the time difference exceeds 3 hours
      localStorage.clear();
      window.location.reload();
    }
  }
};

const showInactivityModal = (continueCallback) => {
  // Check if the user is logged in before showing the modal
  if (localStorage.getItem("userId")) {
    // Check inactivity time first
    checkInactivityTime();

    const logoutTimeout = setTimeout(() => {
      // Trigger logout function
      localStorage.clear();
      window.location.reload();
    }, 10000); // Log the user out after 10 seconds

    swal({
      title: "Inactivity Detected",
      text: "You have been inactive for too long. Do you want to continue?",
      icon: "warning",
      buttons: {
        logout: {
          text: "Logout",
          className: "modal-button",
          value: true,
        },
        continue: {
          text: "Continue",
          value: false,
        },
      },
    }).then((result) => {
      clearTimeout(logoutTimeout); // Clear the logout timeout
      if (result !== null) {
        if (result === true) {
          // Trigger logout function
          localStorage.clear();
          window.location.reload();
        } else {
          // Trigger continue function
          if (continueCallback) {
            continueCallback();
            // Get the current date and time
            const currentDateTime = new Date();
            // Convert the date and time to a string (you can format it as needed)
            const formattedDateTime = currentDateTime.toISOString();
            // Store the formatted date and time in localStorage
            localStorage.setItem("lastVisitDateTime", formattedDateTime);
          }
        }
      }
    });
  }
};

export default showInactivityModal;
