// src/pages/FeedbackAdmin.jsx

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TD, Table } from "../../../components/table/Table";
import { TableLoader } from "../../../components/Loader";
import { useMutation, useQuery } from "@apollo/client";
import { GETCONTACTSBYADMIN } from "./data/query";
import { DeleteModal } from "../../../components/modal/Delete";
import { DELETECONTACTMESSAGE } from "./data/mutation";

const ContactsAdmin = ({ approved }) => {
  const [page, setPage] = useState(0);
  const [itemPerPage, setItemPerPage] = useState(5);
  const [isOpen, setIsOpen] = useState(false);
  const [id, setId] = useState(null);

  const navigate = useNavigate();

  const { data, loading, refetch } = useQuery(GETCONTACTSBYADMIN);
  const [del] = useMutation(DELETECONTACTMESSAGE);

  const thead = [
    { head: "Email" },
    { head: "Subject" },
    { head: "Body" },
    { head: "Actions" }
  ];

  const handleDelete = async (id) => {
    // await del({
    //   variables: {
    //     id
    //   }
    // });
    // refetch();
    console.log(id);
    setIsOpen(false);
  };

  useEffect(() => {
    refetch();
  }, [data]);

  return (
    <>
      {loading ? (
        <TableLoader />
      ) : (
        <Table
          title={"Contact Messages"}
          data={thead}
          noCrud={true}
          setPage={setPage}
          page={page}
          itemPerPage={itemPerPage}
          totalCount={data?.aggregate?.count}
        >
          {data?.contact_us?.map(({ id, subject, body, email }, index) => {
            return (
              <tr className="border p-1 hover:bg-gray-50/90" key={id}>
                <TD>{index + 1}</TD>
                <TD>{email}</TD>
                <TD>{subject}</TD>
                <TD>
                  <p className="text-wrap w-[500px]">{body}</p>
                </TD>
                <TD>
                  <button
                    onClick={() => {
                      setId(id);
                      setIsOpen(true);
                    }}
                    className="text-red-500"
                  >
                    Delete
                  </button>
                </TD>
              </tr>
            );
          })}
          <DeleteModal
            isOpen={isOpen}
            courseId={id}
            handleModal={() => setIsOpen(!isOpen)}
            handleDelete={() => handleDelete(id)}
          />
        </Table>
      )}
    </>
  );
};

export default ContactsAdmin;
