// src/pages/FeedbackAdmin.jsx

import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TD, Table } from "../../../components/table/Table";
import { TableLoader } from "../../../components/Loader";
import { useMutation, useQuery } from "@apollo/client";
import { GETFEEDBACKBYADMIN } from "./data/query";
import { AuthContext } from "../../../context/AuthContext";
import { DELETECOURSEFEEDBACK } from "../course/data/mutation";
import { DeleteModal } from "../../../components/modal/Delete";

const FeedbackAdmin = ({ approved }) => {
  const [page, setPage] = useState(0);
  const [itemPerPage, setItemPerPage] = useState(5);
  const [isOpen, setIsOpen] = useState(false);
  const [id, setId] = useState(null);

  const { userId } = useContext(AuthContext);
  const navigate = useNavigate();

  const { data, loading, refetch } = useQuery(GETFEEDBACKBYADMIN);
  const [del] = useMutation(DELETECOURSEFEEDBACK);

  const thead = [
    { head: "Course Name" },
    { head: "Feedback Title" },
    { head: "Feedback Body" },
    { head: "Actions" }
  ];

  const handleDelete = async (feedbackId) => {
    await del({
      variables: {
        feedbackId
      }
    });
    refetch();
    setIsOpen(false);
  };

  useEffect(() => {
    refetch();
  }, [data]);

  const viewFeedbackDetail = (feedbackId) => {
    navigate(`/dashboard/feedbacks/${feedbackId}`);
  };

  return (
    <>
      {loading ? (
        <TableLoader />
      ) : (
        <Table
          title={"Feedbacks"}
          data={thead}
          noCrud={true}
          setPage={setPage}
          page={page}
          itemPerPage={itemPerPage}
          totalCount={data?.aggregate?.count}
        >
          {data?.course_submission_feedback?.map(
            ({ id, feedback_title, feedback, course }, index) => {
              const feedbackPreview = feedback?.slice(0, 10);
              return (
                <tr className="border p-1 hover:bg-gray-50/90" key={id}>
                  <TD>{index + 1}</TD>
                  <TD>{course?.name}</TD>
                  <TD>{feedback_title}</TD>
                  <TD>
                    {feedbackPreview}...
                    <button
                      onClick={() => viewFeedbackDetail(id)}
                      className="text-blue-500 ml-2"
                    >
                      See More
                    </button>
                  </TD>
                  <TD>
                    <button
                      onClick={() => {
                        setId(id);
                        setIsOpen(true);
                      }}
                      className="text-red-500"
                    >
                      Delete
                    </button>
                  </TD>
                </tr>
              );
            }
          )}
          <DeleteModal
            isOpen={isOpen}
            courseId={id}
            handleModal={() => setIsOpen(!isOpen)}
            handleDelete={handleDelete}
          />
        </Table>
      )}
    </>
  );
};

export default FeedbackAdmin;
