import React, { useState } from "react";
import { CREATECOURSEFEEDBACK } from "../../pages/dashboard/course/data/mutation";
import { useMutation, useQuery } from "@apollo/client";
import { TopToast } from "../../components/Toast";

export const FeedbackModal = ({
  handleFeedback,
  handleModal,
  isOpen,
  courseId,
  userId,
  text,
  statusUser,
}) => {
  const [createFeedback] = useMutation(CREATECOURSEFEEDBACK);
  const [status, setStatus] = useState({
    success: false,
    error: false,
    successContent: "",
    errorContent: "",
  });

  const [feedback, setFeedback] = useState({
    feedback_title: "",
    feedback_body: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFeedback((prevFeedback) => ({
      ...prevFeedback,
      [name]: value,
    }));
  };

  const handleFeedbackSubmit = async (e) => {
    e.preventDefault();

    try {
      await createFeedback({
        variables: {
          courseId: courseId,
          userId: userId,
          feedback_title: feedback.feedback_title,
          feedback: feedback.feedback_body,
        },
      });
      setStatus({
        ...status,
        success: true,
        successContent: status
          ? "Feedback Submitted Successfully"
          : "Feedback Submitted Successfully",
      });
      setFeedback({ feedback_body: "", feedback_title: "" });
      // handleModal();
    } catch (error) {
      setStatus({
        ...status,
        success: false,
        error: true,
        errorContent: error?.graphQLErrors?.[0]?.message,
      });
    }
  };

  return (
    <>
      <div className={isOpen ? "" : "hidden"}>
        <div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5, ease: "easeOut" }}
          aria-hidden="true"
          className="overflow-y-auto overflow-x-hidden fixed top-0 flex left-0 z-50 justify-center items-center w-full min-h-full bg-gray-700/20"
        >
          <div className="relative p-4 w-full max-w-md h-full md:h-auto">
            {/* Modal content */}
            <div className="relative p-4 text-center bg-white rounded-lg sm:p-5">
              <button
                type="button"
                className="absolute top-2. sr-only right-2.5 bg-transparent hover:bg-gray-200 rounded-lg p-1.5 ml-auto inline-flex items-center group"
                onClick={handleModal}
              >
                <svg
                  aria-hidden="true"
                  className="w-5 h-5 fill-current group-hover:fill-red-600"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>

              <form onSubmit={handleFeedbackSubmit}>
                <p className="text-green-300 flex justify-start items-start">
                  {status.successContent}
                </p>
                <div className="mb-6">
                  <input
                    name="feedback_title"
                    value={feedback.feedback_title}
                    onChange={(e) =>
                      setFeedback({
                        ...feedback,
                        feedback_title: e.target.value,
                      })
                    }
                    type="text"
                    id="feedback1"
                    name="feedback1"
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                    required
                    placeholder="Write title here..."
                  />
                  <textarea
                    name="feedback_body"
                    value={feedback.feedback_body}
                    onChange={(e) =>
                      setFeedback({
                        ...feedback,
                        feedback_body: e.target.value,
                      })
                    }
                    className="mt-3 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                    id="w3review"
                    name="w3review"
                    rows="4"
                    cols="50"
                    placeholder="Write description here..."
                  ></textarea>
                </div>

                <div className="flex justify-center items-center space-x-4">
                  <button
                    onClick={handleModal}
                    type="button"
                    className="py-2 px-3 text-sm font-medium text-gray-800 bg-gray-100 rounded-lg border border-gray-300 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-primary-300 hover:text-gray-900 focus:z-10"
                  >
                    Close
                  </button>
                  <button
                    type="submit"
                    className="py-2 px-3 text-sm font-medium text-center text-white bg-green-300/80 rounded-lg hover:bg-green-500 focus:ring-4 focus:outline-none focus:ring-green-200"
                  >
                    Send feedback
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
