import React from "react";
import { Link, NavLink } from "react-router-dom";
import { Logo } from "../../components/Logo";
import { Subscription } from "./Subscription";

const Footer = () => {
  return (
    <>
      <footer className="mt-auto py-8 px-4 bg-gray-900 text-custom-white-100 lg:py-16 xl:px-0 border-t border-gray-700/70">
        <div className="max-w-7xl mx-auto">
          <nav className="w-full flex flex-wrap justify-between pb-12">
            <div className="w-full mb-6 md:w-[20%] lg:w-[15%]">
              <Logo />
            </div>
            <div className="w-1/2 mb-6 md:w-[15%] lg:w-[10%]">
              <h2 className="font-bold mb-4">About us</h2>
              <ul className="text-gray-400">
                <li className="pb-2 hover:text-gray-500">
                  <Link to="/course/#course-section">Courses</Link>
                </li>
                <li className="pb-2 hover:text-gray-500">
                  <Link to="/blog">Blog</Link>
                </li>
                <li className="pb-2 hover:text-gray-500">
                  <Link to="/#faq-section">FAQs</Link>
                </li>
                <li className="pb-2 hover:text-gray-500">
                  <Link to="/#contact-section">Contact Us</Link>
                </li>
                {/* <li className="pb-2 hover:text-gray-500">
                  <Link to="/">Support</Link>
                </li> */}
              </ul>
            </div>
            <div className="w-1/2 mb-6 md:w-[15%] lg:w-[10%]">
              <h2 className="font-bold mb-4">Resources</h2>
              <ul className="text-gray-400">
                <li className="pb-2 hover:text-gray-500">
                  <Link to="/privacy">Privacy</Link>
                </li>
                {/* <li className="pb-2 hover:text-gray-500">
                  <Link to="/">Help Center</Link>
                </li>
                <li className="pb-2 hover:text-gray-500">
                  <Link to="/">Careers</Link>
                </li>
                <li className="pb-2 hover:text-gray-500">
                  <Link to="/">Partners</Link>
                </li> */}
              </ul>
            </div>
            {/* <div className="w-1/2 mb-6 md:w-[15%] lg:w-[10%]">
              <h2 className="font-bold mb-4">Events</h2>
              <ul className="text-gray-400">
                <li className="pb-2 hover:text-gray-500">
                  <Link to="/">Press</Link>
                </li>
                <li className="pb-2 hover:text-gray-500">
                  <Link to="/">Sitemap</Link>
                </li>
              </ul>
            </div> */}
            <div className="w-full lg:w-[40%] xl:w-1/3">
              <Subscription />
              <p className="text-gray-300 text-xs my-2">
                By subscribing, you agree to our Privacy Policy and consent to receive
                updates.
              </p>
              <p className="text-xs text-gray-400/90">
                &#169; {new Date().getFullYear()} Naifty Academy. All rights reserved.
              </p>
            </div>
          </nav>
          <div className="w-full flex flex-wrap justify-between border-t border-gray-700 pt-4">
            <ul className="flex text-xs gap-2 mb-4 md:mb-0 md:gap-4">
              {/* <li className="text-gray-400 transition hover:text-gray-500">
                <NavLink>Cookie Settings</NavLink>
              </li> */}
              <li className="text-gray-400 transition hover:text-gray-500">
                <NavLink to="/privacy">Privacy Policy</NavLink>
              </li>
            </ul>
            <div className="flex gap-4">
              <NavLink
                className="text-gray-400 transition hover:text-gray-500"
                to="https://x.com/Naiftymarket"
                target="_blank"
                rel="noreferrer"
              >
                <span className="sr-only"> X </span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  className="fill-current"
                >
                  <path d="M 4.4042969 3 C 3.7572969 3 3.3780469 3.7287656 3.7480469 4.2597656 L 9.7363281 12.818359 L 3.7246094 19.845703 C 3.3356094 20.299703 3.6578594 21 4.2558594 21 L 4.9199219 21 C 5.2129219 21 5.4916406 20.871437 5.6816406 20.648438 L 10.919922 14.511719 L 14.863281 20.146484 C 15.238281 20.680484 15.849953 21 16.501953 21 L 19.835938 21 C 20.482937 21 20.862187 20.272188 20.492188 19.742188 L 14.173828 10.699219 L 19.900391 3.9902344 C 20.232391 3.6002344 19.955359 3 19.443359 3 L 18.597656 3 C 18.305656 3 18.027891 3.1276094 17.837891 3.3496094 L 12.996094 9.0097656 L 9.3945312 3.8554688 C 9.0205313 3.3194687 8.4098594 3 7.7558594 3 L 4.4042969 3 z"></path>
                </svg>
              </NavLink>
              <NavLink
                className="text-gray-400 transition hover:text-gray-500"
                to="https://t.me/+vyxAX3q_dQA5OGU0"
                target="_blank"
                rel="noreferrer"
              >
                <span className="sr-only"> Telegram </span>
                <svg
                  viewBox="0 0 32 32"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  className="fill-current w-6 h-6 -mt-0.5"
                >
                  <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    <title>telegram</title>
                    <path d="M22.122 10.040c0.006-0 0.014-0 0.022-0 0.209 0 0.403 0.065 0.562 0.177l-0.003-0.002c0.116 0.101 0.194 0.243 0.213 0.403l0 0.003c0.020 0.122 0.031 0.262 0.031 0.405 0 0.065-0.002 0.129-0.007 0.193l0-0.009c-0.225 2.369-1.201 8.114-1.697 10.766-0.21 1.123-0.623 1.499-1.023 1.535-0.869 0.081-1.529-0.574-2.371-1.126-1.318-0.865-2.063-1.403-3.342-2.246-1.479-0.973-0.52-1.51 0.322-2.384 0.221-0.23 4.052-3.715 4.127-4.031 0.004-0.019 0.006-0.040 0.006-0.062 0-0.078-0.029-0.149-0.076-0.203l0 0c-0.052-0.034-0.117-0.053-0.185-0.053-0.045 0-0.088 0.009-0.128 0.024l0.002-0.001q-0.198 0.045-6.316 4.174c-0.445 0.351-1.007 0.573-1.619 0.599l-0.006 0c-0.867-0.105-1.654-0.298-2.401-0.573l0.074 0.024c-0.938-0.306-1.683-0.467-1.619-0.985q0.051-0.404 1.114-0.827 6.548-2.853 8.733-3.761c1.607-0.853 3.47-1.555 5.429-2.010l0.157-0.031zM15.93 1.025c-8.302 0.020-15.025 6.755-15.025 15.060 0 8.317 6.742 15.060 15.060 15.060s15.060-6.742 15.060-15.060c0-8.305-6.723-15.040-15.023-15.060h-0.002q-0.035-0-0.070 0z"></path>
                  </g>
                </svg>
              </NavLink>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
