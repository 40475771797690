import { useEffect, useState } from "react";
import showInactivityModal from "./InActivityModal";

function useSessionChecker() {
  const [secondsRemaining, setSecondsRemaining] = useState(10);
  const [isOpen, setIsOpen] = useState(true);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  function handleUserActivity() {
    // Reset the timer on user activity
    setSecondsRemaining(10);
  }
  useEffect(() => {
    let intervalId = null;

    if (secondsRemaining > 0) {
      intervalId = setInterval(() => {
        setSecondsRemaining(secondsRemaining - 1);
      }, 360000);
    } else {
      // Session expired, show modal with continueCallback function
      showInactivityModal(handleUserActivity);
    }

    return () => clearInterval(intervalId);
  }, [secondsRemaining]);

  // Attach an event listener to reset the timer on user activity
  useEffect(() => {
    window.addEventListener("mousemove", handleUserActivity);
    window.addEventListener("keypress", handleUserActivity);

    return () => {
      window.removeEventListener("mousemove", handleUserActivity);
      window.removeEventListener("keypress", handleUserActivity);
    };
  }, []);
}

export default useSessionChecker;
