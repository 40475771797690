import React, { useContext, useEffect, useState } from "react";
import { TD, Table } from "../../../components/table/Table";
import { TableLoader } from "../../../components/Loader";
import { useQuery } from "@apollo/client";
import { GETFEEDBACKBYINSTRUCTORID } from "./data/query";
import { AuthContext } from "../../../context/AuthContext";

const FeedbackList = ({ courseId }) => {
  const [page, setPage] = useState(0);
  const [itemPerPage, setItemPerPage] = useState(5);
  const [expandedFeedback, setExpandedFeedback] = useState({});

  const { userId } = useContext(AuthContext);

  const { data, loading, refetch } = useQuery(GETFEEDBACKBYINSTRUCTORID, {
    variables: {
      course_id: courseId,
      limit: itemPerPage,
      offset: page * itemPerPage
    }
  });

  const thead = [
    { head: "Course Name" },
    { head: "Feedback Title" },
    { head: "Feedback Body" }
  ];

  useEffect(() => {
    refetch();
  }, [data]);

  const toggleFeedback = (id) => {
    setExpandedFeedback((prevState) => ({
      ...prevState,
      [id]: !prevState[id]
    }));
  };

  return (
    <>
      {loading ? (
        <TableLoader />
      ) : (
        <>
          <Table
            title={"Feedbacks"}
            data={thead}
            noCrud={true}
            setPage={setPage}
            page={page}
            itemPerPage={itemPerPage}
            totalCount={data?.aggregate?.count}
          >
            {data?.course_submission_feedback?.map(
              ({ id, feedback_title, feedback, course }) => {
                const isExpanded = expandedFeedback[id];
                const feedbackPreview = feedback?.slice(0, 60);
                return (
                  <tr className="border p-1 hover:bg-gray-50/90" key={id}>
                    <TD>{course?.instructor?.id}</TD>
                    <TD>{course?.name}</TD>
                    <TD>{feedback_title}</TD>
                    <TD>
                      {isExpanded ? feedback : `${feedbackPreview}...`}
                      <button
                        onClick={() => toggleFeedback(id)}
                        className="text-blue-500 ml-2"
                      >
                        {isExpanded ? "See Less" : "See More"}
                      </button>
                    </TD>
                  </tr>
                );
              }
            )}
          </Table>
        </>
      )}
    </>
  );
};

export default FeedbackList;
