import React, { useState } from "react";
import { CourseCategory } from "../courseCategory/CourseCategory";
import { useQuery } from "@apollo/client";
import { GETCOURSES } from "./data/query";
import { DashH4 } from "../../../components/Heading";
import CourseList from "./components/CourseList";

export const CourseGrid = () => {
  const { data, loading } = useQuery(GETCOURSES, {
    variables: {
      status: "APPROVED",
    },
  });
  const [searchValue, setSearchValue] = useState("");


  return (
    <>
      <div className="w-64">
        <input
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          type="text"
          id="feedback1"
          name="feedback1"
          className="mt-1 mb-4 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
          required
          placeholder="Search Courses..."
        />
      </div>
      <DashH4 text="Popular Topics" />
      <div className="h-48 max-w-7xl overflow-x-auto scrollbar-hide flex space-x-4">
        <CourseCategory />
      </div>
      <div className="border-t border-gray-200 py-8 my-8">
        <DashH4 text="Recent Courses" />
        <CourseList
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          loading={loading}
          data={data}
        />
      </div>
    </>
  );
};
