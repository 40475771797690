import React, { useState } from "react";
import { LandPrimaryButton, PrimaryButton } from "../../components/Button";
import { CREATESUB } from "../../pages/landing/data/mutation";
import { useMutation } from "@apollo/client";
import { Toast } from "../../components/Toast";

export const Subscription = () => {
  const [email, setEmail] = useState();
  const [close, setClose] = useState(true);
  const [createSub, { loading, error }] = useMutation(CREATESUB, {
    variables: {
      email: email
    }
  });
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (loading) return;
    createSub().then(() => {
      setClose(false);
      setEmail("");
    });
  };
  return (
    <>
      <Toast
        isSuccess={error ? false : true}
        text={error ? error.message : "Subscribed successfully"}
        close={close}
        setClose={setClose}
      />
      <div>
        <form className="flex gap-2" onSubmit={handleSubmit}>
          <div className="w-full">
            <input
              id="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="bg-custom-white-100 text-black rounded-2xl focus:ring-blue-500 focus:border-blue-500 w-full p-2.5 outline-none"
              placeholder="johndoe@gmail.com"
              required
            />
          </div>
          <LandPrimaryButton type="submit" text="Subscribe" />
        </form>
      </div>
    </>
  );
};
