import React, { useEffect, useRef } from "react";
import Footer from "../../layouts/landing/Footer";
import Header from "../../layouts/landing/Header";
import { Contact, Cta, FAQ, Feature, Sections } from "./component/HomeSection";
import { PrimaryLink } from "../../components/Link";
import { Link, useLocation } from "react-router-dom";
import { TestimonialCard } from "./component/common/Card";
import { H2, Subtitle } from "../../components/Heading";
import { productData } from "./data/data";

const Privacy = () => {
  const location = useLocation();
  const section1 = useRef(null);
  const sectionC = useRef(null);

  useEffect(() => {
    if (location.hash) {
      if (section1.current && location.hash === "#faq-section") {
        section1.current.scrollIntoView({ behavior: "smooth" });
      }
      if (sectionC.current && location.hash === "#contact-section") {
        sectionC.current.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  return (
    <div className="font-naifty">
      <div className="w-full bg-cover bg-center relative bg-blend-overlay bg-custom-gray-900 xl:px-0">
        <Header />
        <div className="max-w-7xl px-4 m-auto pt-32 pb-8 lg:pt-32">
          <h1
            className="before:w-full before:h-12 before:bg-gray-600 before:my-auto
              font-extrabold text-3xl mb-4 text-gray-200 lg:text-5xl"
          >
            Privacy Policy
          </h1>
          <Subtitle text="Last Updated: August 14, 2024." />

          <div className="mx-auto text-white w-full">
            <p>
              Thank you for choosing Nifty Academy. We respect your privacy and are
              committed to protecting your personal data. This Privacy Policy explains how
              we collect, use, and share your data when you interact with our services,
              including our website, mobile applications, APIs, or other related services
              (collectively, the “Services”). By using our Services, you agree to the
              terms outlined in this Privacy Policy. If you do not agree with this policy,
              please refrain from using our Services.
            </p>
          </div>
          <div className="-mx-2 my-5 text-white w-full">
            <h1 className="font-bold text-3xl">1.Data Collection</h1>
            <p className="mt-3 mx-3">
              We collect data to improve your experience and provide personalized
              services. The data we collect falls into two main categories:
            </p>
            <h1 className="font-bold text-xl mt-5">1.1 Data You Provide</h1>
            <p className="mt-2 mx-3">
              - <span className="font-semibold">Account Data:</span> When you create an
              account, we collect your email, password, and any additional information you
              provide, such as your name, phone number, or profile details.
            </p>
            <p className="mt-2 mx-3">
              - <span className="font-semibold">Profile Data:</span> - You may provide
              information like your biography, website link, or social media profiles,
              which may be publicly viewable.
            </p>
            <p className="mt-2 mx-3">
              - <span className="font-semibold">Shared Content:</span> - We collect data
              from content you share, such as course materials, reviews, or messages. This
              content may be publicly accessible depending on your settings.
            </p>
            <p className="mt-2 mx-3">
              - <span className="font-semibold">Payment Data:</span> - If you make
              purchases, we collect data necessary to process your payment, such as your
              name, billing address, and payment details.
            </p>
            {/*  */}
            <h1 className="font-bold text-xl mt-5">1.2 Data We Collect Automatically</h1>
            <p className="mt-2 mx-3">
              - <span className="font-semibold">System Data:</span> - We collect technical
              information about your device, such as IP address, browser type, and
              operating system.
            </p>
            <p className="mt-2 mx-3">
              - <span className="font-semibold">Usage Data:</span> - We gather data about
              your interactions with our Services, including pages visited, content
              accessed, and time spent on the Services.
            </p>
            <p className="mt-2 mx-3">
              - <span className="font-semibold">Geographic Data:</span> - We may collect
              approximate geographic location data based on your IP address.
            </p>
          </div>
          {/*  */}
          <div className="-mx-2 my-5 text-white w-full">
            <h1 className="font-bold text-3xl">2.Data Usage</h1>
            <p className="mt-3 mx-3">We use your data to:</p>
            <p className="mt-2 mx-3">
              - Provide and manage our Services, including course content, payment
              processing, and communication.
            </p>
            <p className="mt-2 mx-3">
              - Improve your experience by customizing content, features, and services
              based on your preferences and interactions.
            </p>
            <p className="mt-2 mx-3">
              - Protect against fraud, ensure security, and comply with legal obligations.
            </p>
          </div>
          {/*  */}
          <div className="-mx-2 my-5 text-white w-full">
            <h1 className="font-bold text-3xl">3.Data Sharing</h1>
            <p className="mt-3 mx-3">We may share your data with:</p>
            <p className="mt-2 mx-3">
              - <span className="font-semibold">Instructors:</span> - Data such as your
              country, device type, and course interactions may be shared with instructors
              to improve course content.
            </p>
            <p className="mt-2 mx-3">
              - <span className="font-semibold">Service Providers:</span> - We work with
              third-party service providers to perform tasks on our behalf, such as
              payment processing and data analysis.
            </p>
            <p className="mt-2 mx-3">
              - <span className="font-semibold">Business Partners:</span> - We may share
              data with partners to distribute our Services and drive traffic to Naifty
              Academy.
            </p>
            <p className="mt-2 mx-3">
              - <span className="font-semibold">Legal and Security Compliance:</span> - We
              may disclose your data if required by law or to protect our rights and
              safety.
            </p>
          </div>
          {/*  */}
          <div className="-mx-2 my-5 text-white w-full">
            <h1 className="font-bold text-3xl">4.Security</h1>
            <p className="mt-3 mx-3">
              We implement appropriate security measures to protect your data. However, no
              system is completely secure, so we encourage you to safeguard your account
              credentials.
            </p>
          </div>
          {/*  */}
          <div className="-mx-2 my-5 text-white w-full">
            <h1 className="font-bold text-3xl">5.Your Rights</h1>
            <p className="mt-3 mx-3">You have the right to:</p>
            <p className="mt-2 mx-3">
              - <span className="font-semibold">Access and Update:</span> - Access and
              update your personal data by logging into your account.
            </p>
            <p className="mt-2 mx-3">
              - <span className="font-semibold">Opt-Out:</span> - Opt-out of receiving
              promotional communications through your account settings or unsubscribe
              links.
            </p>
            <p className="mt-2 mx-3">
              - <span className="font-semibold">Delete:</span> - Request the deletion of
              your personal data, subject to certain conditions.
            </p>
          </div>
          {/*  */}
          <div className="-mx-2 my-5 text-white w-full">
            <h1 className="font-bold text-3xl">6. Jurisdiction-Specific Rules</h1>
            <p className="mt-3 mx-3">
              Depending on your location, additional privacy rights may apply, such as
              rights under the GDPR for users in the European Economic Area or rights
              under the California Consumer Privacy Act (CCPA) for users in California.
            </p>
          </div>
          {/*  */}
          <div className="-mx-2 my-5 text-white w-full">
            <h1 className="font-bold text-3xl">7. Updates to this Policy</h1>
            <p className="mt-3 mx-3">
              We may update this Privacy Policy from time to time. Material changes will
              be communicated via email or through in-service notifications. Continued use
              of our Services after any changes signifies your acceptance of the revised
              policy.
            </p>
          </div>
          {/*  */}
          <div className="-mx-2 my-5 text-white w-full">
            <h1 className="font-bold text-3xl">8. Contact Information</h1>
            <p className="mt-3 mx-3">
              If you have any questions or concerns regarding this Privacy Policy, please
              contact us at info@naifty.academy.
            </p>
          </div>
          {/*  */}
          <div className="-mx-2 my-5 text-white w-full">
            <h1 className="font-bold text-3xl">
              9. Naifty Academy Courses and Content Categories
            </h1>
            <p className="mt-3 mx-3">
              At Naifty Academy, we offer a wide range of courses across multiple
              disciplines to cater to your learning needs. Our categories include:
            </p>
            <p className="mt-2 mx-3">
              - <span className="font-semibold">Development:</span> - Web Development,
              Data Science, Mobile Development, Programming Languages, Game Development,
              Database Design & Development, Software Testing, Software Engineering,
              Software Development Tools, No-Code Development.
            </p>
            <p className="mt-2 mx-3">
              - <span className="font-semibold">Business:</span> -Entrepreneurship,
              Communication, Management, Sales, Business Strategy, Operations, Project
              Management, Business Law, Business Analytics & Intelligence, Human
              Resources, Industry, E-Commerce, Media, Real Estate, Other Business.
            </p>
            <p className="mt-2 mx-3">
              - <span className="font-semibold">Finance & Accounting:</span> - Accounting
              & Bookkeeping, Compliance, Cryptocurrency & Blockchain, Economics, Finance,
              Finance Certification & Exam Prep, Financial Modeling & Analysis, Investing
              & Trading, Money Management Tools, Taxes, Other Finance & Accounting.
            </p>
            <p className="mt-2 mx-3">
              - <span className="font-semibold">IT & Software:</span> - WIT
              Certifications, Network & Security, Hardware, Operating Systems & Servers,
              Other IT & Software.
            </p>
            <p className="mt-2 mx-3">
              - <span className="font-semibold">Office Productivity:</span> - Microsoft,
              Apple, Google, SAP, Oracle, Other Office Productivity.
            </p>
            <p className="mt-2 mx-3">
              - <span className="font-semibold">Personal Development:</span> - Personal
              Transformation, Personal Productivity, Leadership, Career Development,
              Parenting & Relationships, Happiness, Esoteric Practices, Religion &
              Spirituality, Personal Brand Building, Creativity, Influence, Self Esteem &
              Confidence, Stress Management, Memory & Study Skills, Motivation, Other
              Personal Development.
            </p>
            <p className="mt-2 mx-3">
              - <span className="font-semibold">Design:</span> - Web Design, Graphic
              Design & Illustration, Design Tools, User Experience Design, Game Design, 3D
              & Animation, Fashion Design, Architectural Design, Interior Design, Other
              Design.
            </p>
            <p className="mt-2 mx-3">
              - <span className="font-semibold">Marketing:</span> - Digital Marketing,
              Search Engine Optimization, Social Media Marketing, Branding, Marketing
              Fundamentals, Marketing Analytics & Automation, Public Relations, Paid
              Advertising, Video & Mobile Marketing, Content Marketing, Growth Hacking,
              Affiliate Marketing, Product Marketing, Other Marketing.
            </p>
            <p className="mt-2 mx-3">
              - <span className="font-semibold">Lifestyle:</span> - Arts & Crafts, Beauty
              & Makeup, Esoteric Practices, Food & Beverage, Gaming, Home Improvement &
              Gardening, Pet Care & Training, Travel, Other Lifestyle.
            </p>
            <p className="mt-2 mx-3">
              - <span className="font-semibold">Photography & Video:</span> - Digital
              Photography, Photography, Portrait Photography, Photography Tools,
              Commercial Photography, Video Design, Other Photography & Video.
            </p>
            <p className="mt-2 mx-3">
              - <span className="font-semibold">Health & Fitness:</span> -Fitness, General
              Health, Sports, Nutrition & Diet, Yoga, Mental Health, Martial Arts & Self
              Defense, Safety & First Aid, Dance, Meditation, Other Health & Fitness.
            </p>
            <p className="mt-2 mx-3">
              - <span className="font-semibold">Music:</span> - Instruments, Music
              Production, Music Fundamentals, Vocal, Music Techniques, Music Software,
              Other Music.
            </p>
            <p className="mt-2 mx-3">
              - <span className="font-semibold">Teaching & Academics:</span> -
              Engineering, Humanities, Math, Science, Online Education, Social Science,
              Language Learning, Teacher Training, Test Prep, Other Teaching & Academics.
            </p>
          </div>
          {/*  */}
          <div className="-mx-2 my-5 text-white w-full">
            <h1 className="font-bold text-3xl">10. Terms and Conditions</h1>
            <p className="mt-3 mx-3">
              By using our platform, you agree to comply with our Terms of Use, which are
              designed to ensure a safe and productive learning environment for all users.
              Please review our full Terms of Use, Privacy Policy, and other related
              agreements on our website.
            </p>
          </div>
          {/*  */}
          <div className="-mx-2 my-5 text-white w-full">
            <h1 className="font-bold text-3xl">11. Intellectual Property</h1>
            <p className="mt-3 mx-3">
              All content, including courses, graphics, text, and software, available on
              Naifty Academy is the intellectual property of Naifty Academy and its
              content creators. Unauthorized use, distribution, or reproduction of this
              content is strictly prohibited.
            </p>
          </div>
          {/*  */}
          <div className="-mx-2 my-5 text-white w-full">
            <h1 className="font-bold text-3xl">12. Cookies and Data Collection Tools</h1>
            <p className="mt-3 mx-3">
              We use cookies and similar technologies to enhance your experience on our
              platform. These tools help us understand how you use our Services and enable
              us to provide a more personalized experience. You can manage your cookie
              preferences through your browser settings.
            </p>
          </div>
          {/*  */}
          <div className="-mx-2 my-5 text-white w-full">
            <h1 className="font-bold text-3xl">13. Contact Information</h1>
            <p className="mt-3 mx-3">
              For any inquiries related to this Privacy Policy or our platform, please
              reach out to us at info@naifty.academy or through our contact form on the
              website.
            </p>
          </div>
          {/*  */}
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Privacy;
