import React, { useEffect, useState } from "react";
import { GETSTUDENTUSERS, GETSTUDENTUSERSCOUNT, SEARCHSTUDENTUSERS } from "./data/query";
import UserTable from "./component/UserTable";
import { useQuery } from "@apollo/client";

const StudentList = () => {
  const [page, setPage] = useState(0);
  const [itemPerPage, setItemPerPage] = useState(5);
  const [searchValue, setSearchValue] = useState("");
  const [searchedData, setSearchedData] = useState([]);

  const { data, loading } = useQuery(GETSTUDENTUSERS, {
    fetchPolicy: "network-only",
    variables: {
      limit: itemPerPage,
      offset: page * itemPerPage
    }
  });
  const { loading: searchLoad, refetch: search } = useQuery(SEARCHSTUDENTUSERS, {
    fetchPolicy: "network-only",
    variables: {
      search: searchValue
    }
  });

  const { data: totalCount } = useQuery(GETSTUDENTUSERSCOUNT, {
    fetchPolicy: "network-only"
  });

  const handleSearch = async () => {
    if (searchValue) {
      search().then((res) => {
        setSearchedData({
          users: res.data?.student_profile?.map((item) => {
            return {
              id: item.user.id,
              email: item.user.email,
              is_active: item.user.is_active,
              is_instructor: item.user.is_instructor,
              is_superuser: item.user.is_superuser,
              studentprofile: {
                first_name: item.first_name,
                last_name: item.last_name,
                bio: item.bio,
                profile_picture: item.profile_picture
              }
            };
          })
        });
      });
    } else {
      setSearchedData([]);
    }
  };
  useEffect(() => {
    if (searchValue) {
      setTimeout(() => {
        handleSearch();
      }, 1000);
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  return (
    <>
      <div className="w-64">
        <input
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          type="text"
          id="searchStuddent"
          name="searchStuddent"
          className="mt-1 mb-4 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
          required
          placeholder="Search by first name"
        />
      </div>
      <UserTable
        data={searchValue ? searchedData : data}
        loading={loading || searchLoad}
        GETUSERS={GETSTUDENTUSERS}
        setPage={setPage}
        page={page}
        itemPerPage={itemPerPage}
        totalCount={
          searchValue ? searchedData?.users?.length : totalCount?.users_aggregate?.count
        }
      />
    </>
  );
};

export default StudentList;
