import React, { useState } from "react";
import ReactStars from "react-rating-stars-component";

export const Rating = ({ ratings }) => {
  const calulateRating = (ratings) => {
    let sum = 0;
    ratings?.map((rating) => {
      return (sum += rating.rate);
    });
    return Number((sum / ratings.length).toFixed(1));
  };

  return (
    <div>
      <div className="flex gap-1 my-2 ">
        <ReactStars
          {...{
            size: 20,
            value: calulateRating(ratings || []),
            isHalf: true,
            edit: false,
            activeColor: "rgb(234 179 8)",
            onChange: (newValue) => {
              console.log(`New value is ${newValue}`);
            }
          }}
        />
      </div>
    </div>
  );
};

export const Rate = ({ rating, setRating }) => {
  const [color, setColor] = useState({
    one: "",
    two: "",
    three: "",
    four: "",
    five: ""
  });

  const handleColor = (n) => {
    const colors = {
      one: "",
      two: "",
      three: "",
      four: "",
      five: ""
    };

    switch (n) {
      case 1:
        colors.one = "fill-yellow-400";
        setRating(1);
        break;
      case 2:
        colors.one = "fill-yellow-400";
        colors.two = "fill-yellow-400";
        setRating(2);
        break;
      case 3:
        colors.one = "fill-yellow-400";
        colors.two = "fill-yellow-400";
        colors.three = "fill-yellow-400";
        setRating(3);
        break;
      case 4:
        colors.one = "fill-yellow-400";
        colors.two = "fill-yellow-400";
        colors.three = "fill-yellow-400";
        colors.four = "fill-yellow-400";
        setRating(4);
        break;
      case 5:
        colors.one = "fill-yellow-400";
        colors.two = "fill-yellow-400";
        colors.three = "fill-yellow-400";
        colors.four = "fill-yellow-400";
        colors.five = "fill-yellow-400";
        setRating(5);
        break;
      default:
        break;
    }

    setColor(colors);
  };

  return (
    <>
      <svg
        onClick={() => handleColor(1)}
        className={`w-5 h-5 fill-gray-300 ${color.one} cursor-pointer pr-0.5`}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 576 512"
      >
        <path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z" />
      </svg>
      <svg
        onClick={() => handleColor(2)}
        className={`w-5 h-5 fill-gray-300 ${color.two} cursor-pointer pr-0.5`}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 576 512"
      >
        <path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z" />
      </svg>
      <svg
        onClick={() => handleColor(3)}
        className={`w-5 h-5 fill-gray-300 ${color.three} cursor-pointer pr-0.5`}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 576 512"
      >
        <path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z" />
      </svg>
      <svg
        onClick={() => handleColor(4)}
        className={`w-5 h-5 fill-gray-300 ${color.four} cursor-pointer pr-0.5`}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 576 512"
      >
        <path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z" />
      </svg>
      <svg
        onClick={() => handleColor(5)}
        className={`w-5 h-5 fill-gray-300 ${color.five} cursor-pointer pr-0.5`}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 576 512"
      >
        <path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z" />
      </svg>
    </>
  );
};
