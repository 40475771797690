import { useMutation } from "@apollo/client";
import { MINIO_URL } from "../../../../axios/mutation";
import { ToolTip } from "../../../../components/ToolTip";
import { useRole } from "../../../../hooks/useRole";
import { formattedDate } from "../../../../utils/formattedDate";
import { DELETEDISCUSSIONCOMMENT } from "../../forum/data/mutation";
import { useState } from "react";
import { DeleteModal } from "../../../../components/modal/Delete";

export const CommentCard = ({ photo, name, comment, updated_at, id }) => {
  const { isAdmin } = useRole();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [forumId, setForumId] = useState();
  const [del] = useMutation(DELETEDISCUSSIONCOMMENT);

  const handleDeleteClick = (id) => {
    setOpenDeleteModal(!openDeleteModal);
    setForumId(id);
  };

  const handleDelete = async (id) => {
    const { data } = await del({
      variables: {
        commentId: id
      }
    });
    if (data) {
      setOpenDeleteModal(false);
      window.location.reload();
    }
  };

  // console.log(id);

  return (
    <>
      {openDeleteModal && (
        <DeleteModal
          isOpen={openDeleteModal}
          courseId={forumId}
          handleModal={() => setOpenDeleteModal(!openDeleteModal)}
          handleDelete={handleDelete}
        />
      )}
      <li role="article" className="relative pl-8">
        <div className="flex flex-col flex-1 gap-2">
          <div className="absolute z-10 inline-flex items-center justify-center w-8 h-8 text-white rounded-full -left-4 ring-2 ring-white">
            <img
              src={`${MINIO_URL}/media/${photo}`}
              alt={name}
              width="48"
              height="48"
              className="max-w-full h-full rounded-full object-cover"
            />
          </div>
          <div className="flex justify-between">
            <h4 className="text-sm font-medium text-slate-700 md:flex-row lg:items-center">
              <span className="flex-1">{name} </span>
              <span className="text-[0.7rem] font-normal text-slate-400">
                on {formattedDate(updated_at)}
              </span>
            </h4>
            {isAdmin && (
              <button
                type="button"
                className="bg-red-100/50 p-2 inline rounded relative group hover:bg-red-600"
                onClick={() => handleDeleteClick(id)}
              >
                <ToolTip text="Delete" />
                <svg
                  className="w-3 h-3 fill-red-500 group-hover:fill-white"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                >
                  <path d="M170.5 51.6L151.5 80h145l-19-28.4c-1.5-2.2-4-3.6-6.7-3.6H177.1c-2.7 0-5.2 1.3-6.7 3.6zm147-26.6L354.2 80H368h48 8c13.3 0 24 10.7 24 24s-10.7 24-24 24h-8V432c0 44.2-35.8 80-80 80H112c-44.2 0-80-35.8-80-80V128H24c-13.3 0-24-10.7-24-24S10.7 80 24 80h8H80 93.8l36.7-55.1C140.9 9.4 158.4 0 177.1 0h93.7c18.7 0 36.2 9.4 46.6 24.9zM80 128V432c0 17.7 14.3 32 32 32H336c17.7 0 32-14.3 32-32V128H80zm80 64V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16zm80 0V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16zm80 0V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16z" />
                </svg>
              </button>
            )}
          </div>
          <p className="text-slate-500 text-sm leading-3">{comment}</p>
        </div>
      </li>
    </>
  );
};
