import { useContext, useEffect, useState } from "react";
import { GETSTUDENTPROFILE } from "../pages/dashboard/profile/data/query";
import { useQuery } from "@apollo/client";
import { AuthContext } from "../context/AuthContext";

export const useProfilePicture = () => {
  const { userId } = useContext(AuthContext);
  const [profilePicture, setProfilePicture] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const { data, loading } = useQuery(GETSTUDENTPROFILE, {
    variables: { userId: userId },
  });

  useEffect(() => {
    if (data && data?.student_profile.length > 0) {
      const profile = data?.student_profile?.[0];
      setProfilePicture(profile?.profile_picture);
      setFirstName(profile?.first_name);
      setLastName(profile?.last_name);
    }
  }, [data]);

  return { profilePicture, firstName, lastName, loading };
};
